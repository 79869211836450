import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WhyUniqallyService {

  private contents: any = {
    'PRICING' : [
      {
        title : 'Unmatched Precision',
        image : 'wua_unmatched.png',
        description : 'Your tax ally follows time tested proven process leveraging the UniqAlly’ s proprietary tools assuring unmatched ' +
          'accuracy and application of complex IRS codes to successfully prepare your tax return'
      },
      {
        title : 'Guided Experience',
        image : 'guided.svg',
        description : 'Your team of experts will work with you in seamlessly onboarding to the secured platform and easy collaboration ' +
          'throughout the process. The team is constantly in touch guiding you through changing laws.'
      },
      {
        title : 'Maximum Refunds',
        image : 'wu_maximum.png',
        description : 'Your tax ally adopts a unique project execution model when it comes to tax preparation. More than 50% of the time is' +
          ' spent on personalized data gathering ensuring completeness, compliance and exploring all possible tax options to maximize your refunds'
      },
      {
        title : 'IRS Audit Defense',
        image : 'wu_irs.png',
        description : 'Your ally has got you covered. Our tax filing packages comes included with guaranteed audit defense at no additional cost.'
      },
      {
        title : 'Highly Secured',
        image : 'wu_secure.png',
        description : 'UniqAlly takes security seriously and has developed robust internal process to manage Customer data and maintain privacy.' +
          'We have heavily invested in cutting-edge technology solution and also partnered with collaborative solution to manage Customer data'
      },
    ]
  };

  // Get contents for why uniqally
  getContents(page) {
    return this.contents[page];
  }

}
