import { Component, OnInit } from '@angular/core';
import { AuthService } from "angularx-social-login";
import { SpinnerVisibilityService } from 'ng-http-loader';
import { UserService } from '../../_services/user.service';
import { Router } from '@angular/router';
import { HttpService } from '../../_helpers/http.service';


@Component({
  selector: 'app-service-navbar',
  templateUrl: './service-navbar.component.html',
  styleUrls: ['./service-navbar.component.css']
})
export class ServiceNavbarComponent implements OnInit {


  private isCollapsed = false;
  private user;
  private isLoggedIn = false;
  userDashboardURL;

  constructor(
    private authService: AuthService,
    private spinner: SpinnerVisibilityService,
    private userService: UserService,
    private router: Router,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    if(localStorage.getItem('name') !== null && localStorage.getItem('token') !== null){
      this.user = {
        name : localStorage.getItem('name'),
        token : localStorage.getItem('token'),
        email_verified_at : localStorage.getItem('email_verified_at'),
        is_social_auth : localStorage.getItem('is_social_auth'),
        is_two_factor : localStorage.getItem('is_two_factor'),
      }
    }
    if(localStorage.getItem('success_code') !== null){
      this.isLoggedIn = true;
    }
    if(localStorage.getItem('token') !== null && localStorage.getItem('email_verified_at') === 'null'){
      if(!this.router.url.includes('account-verification')) this.router.navigate(['/account-verification']);
    }else if(localStorage.getItem('token') !== null && localStorage.getItem('email_verified_at') !== 'null'
      && localStorage.getItem('is_two_factor') === 'false'){
      this.router.navigate(['/two-factor-authentication']);
    }

    this.userDashboardURL = this.httpService.getUserdashboardURL();
    console.log(this.userDashboardURL);
  }

  private signOut(): void {
    this.authService.signOut();
    this.isLoggedIn = false;
    localStorage.clear();
    this.spinner.show()
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    this.router.navigate(['/']);
  }

  goToUserDashboard(): void{
    window.open(this.userDashboardURL, "_blank");
  }

}
