import { Component, OnInit } from '@angular/core';
import { AuthService } from "angularx-social-login";
import { SpinnerVisibilityService } from 'ng-http-loader';
import { UserService } from '../../_services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styleUrls: ['./dashboard-navbar.component.css']
})
export class DashboardNavbarComponent implements OnInit {

  private isCollapsed = false;
  private user;
  private isLoggedIn = false;

  constructor(
    private authService: AuthService,
    private spinner: SpinnerVisibilityService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    if(localStorage.getItem('name') !== null && localStorage.getItem('token') !== null){
      this.user = {
        name : localStorage.getItem('name'),
        token : localStorage.getItem('token'),
        email_verified_at : localStorage.getItem('email_verified_at'),
        is_social_auth : localStorage.getItem('is_social_auth'),
        is_two_factor : localStorage.getItem('is_two_factor'),
      }
      this.isLoggedIn = true;
    }
    if(localStorage.getItem('token') !== null && localStorage.getItem('email_verified_at') === 'null'){
      if(!this.router.url.includes('account-verification')) this.router.navigate(['/account-verification']);
    }else if(localStorage.getItem('token') !== null && localStorage.getItem('email_verified_at') !== 'null'
      && localStorage.getItem('is_two_factor') === 'false'){
      this.router.navigate(['/two-factor-authentication']);
    }
  }

  private signOut(): void {
    this.authService.signOut();
    this.isLoggedIn = false;
    localStorage.clear();
    this.spinner.show()
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    this.router.navigate(['/']);
  }

}
