import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {HttpService} from '../_helpers/http.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  private reviews;

  constructor(
    private http: HttpClient,
    private httpService: HttpService
  ) {
  }

  // Handle API errors with no response
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      return throwError(
        'Something bad happened; please try again later.');
    } else {
      return throwError(error);
    }
  }

  // Set Reviews object
  setReviews(reviews) {
    this.reviews = reviews;
  }

  // Get Reviews object
  getReviews() {
    return this.reviews;
  }

  // Get details of the Reviews
  getReviewDetails(pageCode): Observable<any> {
    let endpointWithOrWithoutParams = 'guest/customer-reviews?';
    if ( pageCode !== null ) endpointWithOrWithoutParams = endpointWithOrWithoutParams + 'page_code=' + pageCode;
    return this.http
      .get<any>(this.httpService.getApiURL() +  endpointWithOrWithoutParams, this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Add Review
  addReview(params): Observable<any> {
    return this.http
      .post<any>(this.httpService.getApiURL() +  'guest/submit-review', params, this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get details of the Products to show in submit review form
  async getProductDetails(){
    let response = await this.http.get<any>(this.httpService.getApiURL() +  'entity/products', this.httpService.getHeaders()).toPromise();
    return response;
  }

}
