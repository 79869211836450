import { Component, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ReviewService } from '../../_services/review.service';
import { PricingService } from '../../_services/pricing.service';


@Component({
  selector: 'app-customer-review',
  templateUrl: './customer-review.component.html',
  styleUrls: ['./customer-review.component.css']
})
export class CustomerReviewComponent implements OnInit {

  reviewsDetails: any = [];
  availablePackages: any = [];
  selectedPackagesForReview: any = [];
  reviewForm: FormGroup;
  Arr = Array;
  submitted = false;
  showServerErrors = false;
  serverErrMsg;
  showRatingError = false;
  ratingErrMsg = 'Please rate our service to submit a review!';
  showSuccessMessage = false;
  successMessage;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private reviewService: ReviewService,
    private pricingService: PricingService
  ) { }

  async ngOnInit(): Promise<void> {
    this.reviewForm = this.formBuilder.group({
      email: ['', [ Validators.required, Validators.email, Validators.maxLength(250) ]],
      name: ['', [ Validators.required, Validators.maxLength(250) ]],
      comment: ['', [ Validators.required, Validators.maxLength(250) ]]
    });

    this.getReviewDetails();
    this.getPricingDetails();
  }

  // Get details of the reviews
  private getReviewDetails() {
    const pageCode = 'INDIVIDUAL';
    this.reviewService.getReviewDetails(pageCode).subscribe(response => {
      if (response.success === true) {
        this.reviewsDetails = response.data.customerReviews;
        console.log(this.reviewsDetails[0].associated_products[0].title)
      }
    }, error => {
      console.log(error);
    });
  }

  // Triggers when user submits a review
  onSubmit(){
    this.submitted = true;
    const formData = this.reviewForm.value;
    let selectedRating = this.getUserSelectedRating();
    formData.associated_products = JSON.stringify(this.selectedPackagesForReview);
    formData.rating = selectedRating;
    formData.coming_from = 1;

    if (selectedRating !== 0){
      if (!this.reviewForm.invalid) {
        this.reviewService.addReview(formData).subscribe(response => {
          if (response.success === true) {
            this.successMessage = response.message;
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.showSuccessMessage = false;
              this.closeModal();
            }, 6000);
          }
        }, error => {
          console.log(error);
          if (error.error.data){
            this.showServerErrors = true;
            this.serverErrMsg = error.error.data.error.email[0];
            setTimeout(() => {
              this.showServerErrors = false;
            }, 6000);
          }
        });
      }
    }else{
      this.showRatingError = true;
      setTimeout(() => {
        this.showRatingError = false;
      }, 6000);
    }


  }

  // Get the user selected rating
  private getUserSelectedRating() {
    const rating = document.getElementsByName('rating');
    let selectedRating = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < rating.length; i++) {
      if ((rating[i] as HTMLInputElement).checked){
        // tslint:disable-next-line:radix
        selectedRating = parseInt((rating[i] as HTMLInputElement).value);
      }
    }
    return selectedRating;
  }

  // Get product details
  private getPricingDetails() {
    // The parent category is hardcoded todo:: Make the parent category dynamic
    const pageCode = 'INDIVIDUAL';
    const parentCategoryId = null;
    const subCategoryId = null;
    this.pricingService.getPricingPackagesWithCategories(pageCode, parentCategoryId,  ).subscribe(response => {
      if (response.success === true) {
          this.availablePackages = response.data.packages;
      }
    }, error => {
      console.log(error);
    });
  }

  // Open submit review modal dialog
  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {});
  }

  // Close submit review modal dialog
  private closeModal() {
    let element: HTMLElement = document.getElementById('modalCloseButton') as HTMLElement;
    element.click();
  }

}
