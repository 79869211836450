import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { NgbAccordionModule, NgbCarouselModule, NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthComponent } from './social-auth/social-auth.component';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DashboardNavbarComponent } from './dashboard-navbar/dashboard-navbar.component';
import { PlatformSwitcherComponent } from './platform-switcher/platform-switcher.component';
// import { PricingViewerComponent } from './pricing-viewer/pricing-viewer.component';
import { CustomerReviewComponent } from './customer-review/customer-review.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { WhyUniqallyComponent } from './why-uniqally/why-uniqally.component';
import { ServiceNavbarComponent } from './service-navbar/service-navbar.component';
import { ServiceFooterComponent } from './service-footer/service-footer.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDividerModule} from '@angular/material/divider';
import { HowitworksComponent } from './howitworks/howitworks.component';
// ServiceBlogComponent



@NgModule({
    imports: [
      MatDividerModule,
      MatToolbarModule,
      MatFormFieldModule,
      MatIconModule,
        CommonModule,
        RouterModule,
        NgbCollapseModule,
        NgbDropdownModule,
        FormsModule,
        NgbAccordionModule,
        NgbCarouselModule,
        ReactiveFormsModule,
        NgSelectModule,
    ],
  declarations: [
    NavbarComponent,
    FooterComponent,
    SocialAuthComponent,
    SubscriberComponent,
    SidebarComponent,
    DashboardNavbarComponent,
    PlatformSwitcherComponent,
    // PricingViewerComponent,
    CustomerReviewComponent,
    WhyUniqallyComponent,
    ServiceNavbarComponent,
    ServiceFooterComponent,
    HowitworksComponent
  ],
  exports: [
    PlatformSwitcherComponent,
    ServiceFooterComponent,
    ServiceNavbarComponent,
    NavbarComponent,
    FooterComponent,
    SocialAuthComponent,
    SubscriberComponent,
    SidebarComponent,
    DashboardNavbarComponent,
    // PricingViewerComponent,
    CustomerReviewComponent,
    WhyUniqallyComponent,
    HowitworksComponent
  ]
})

export class ElementsModule { }
