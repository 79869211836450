import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  private currentRoute:any;
  private isLogin = false;
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.currentRoute = this.router.url;
    console.log(this.currentRoute);

    if (localStorage.getItem('token') === null) {
      this.isLogin = false
    }else{
      this.isLogin = true
    }
  }

}
