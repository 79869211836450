import { Component ,OnInit} from '@angular/core';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { MetaService } from "../../_services/MetaService";
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {

  private currentRoute;

  constructor(private router : Router, private activatedRoute: ActivatedRoute,    private seoService: MetaService,) { }

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.router.events.pipe(

      
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {

      console.log(data);
      
      let seoData = data['seo'];
      this.seoService.updateTitle(seoData['title']);
      this.seoService.updateMetaTags(seoData['metaTags']);
    });

    
  }

}
