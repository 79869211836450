import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpService } from '../_helpers/http.service';

@Injectable({
  providedIn: 'root'
})

export class PricingService {

  private cart;

  // Class constructor
  constructor(
    private http: HttpClient,
    private httpService: HttpService
  ) {}

  // Handle API errors with no response
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      return throwError(
        'Something bad happened; please try again later.');
    } else {
      return throwError(error.error.message);
    }
  }

  // Get pricing package details for selected category with all package categories
  getPricingPackagesWithCategories(catId, isSCorp): Observable<any> {
    let endpointWithOrWithoutParams = catId !== null ? 'guest/pricing?catId=' + catId + '&isSCorp=' + isSCorp : 'guest/pricing';
    return this.http
      .get<any>(this.httpService.getApiURL() + endpointWithOrWithoutParams, this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Set the user selected cart
  setCart(cart) {
    this.cart = cart;
  }

  // Get the user selected cart
  getCart() {
    return this.cart;
  }

}
