import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpService } from '../_helpers/http.service';

@Injectable({
  providedIn: 'root'
})

export class SubscribeService {

  constructor(
    private http: HttpClient,
    private httpService: HttpService
  ) {}

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      return throwError(
        'Something bad happened; please try again later.');
    } else {
      return throwError(error.error.message);
    }
  }

  // Subscribe emails to send discount codes
  subscribe(email): Observable<any> {
    return this.http
      .post<any>(this.httpService.getApiURL() + 'guest/subscribe', email, this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }
}
