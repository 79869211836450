import { Component, OnInit } from '@angular/core';
import { SubscribeService } from '../../_services/subscribe.service';

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.css']
})
export class SubscriberComponent implements OnInit {

  private response = null;
  private responseAvailable = false;
  private subscribeError = null;
  private subscribeModel:any;

  constructor(
    private subscribeService: SubscribeService
  ) { }

  ngOnInit() {
  }

  // Submit subscription request
  private subscribe() {
    if (this.subscribeError == null) {
      this.subscribeService.subscribe({ email: this.subscribeModel}).subscribe(response => {
        if (response.success === true) {
          this.response = "You've been successfully subscribed to our service!";
          this.responseAvailable = true;
          setTimeout(() => {
            this.responseAvailable = false;
          }, 4000);
        }
      }, error => {
        this.response = 'The email address has been already subscribed to our service!';
        this.responseAvailable = true;
        setTimeout(() => {
          this.responseAvailable = false;
        }, 4000);
      });

    }
  }

  // Custom validation for email input
  private validateEmail(fileInput: any) {
    const regex = new RegExp("[^ @]*@[^ @]*");
    if (fileInput.target.value === '') {
      this.subscribeError = 'Email is required!';
    } else if (!regex.test(fileInput.target.value)) {
      this.subscribeError = 'Email is invalid!';
    } else {
      this.subscribeError = null;
      this.subscribeModel = fileInput.target.value;
    }
  }

}
