import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  // Show about us
  private openAboutUs(aboutUsModal) {
    this.modalService.open(aboutUsModal, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
  }

}
