import { Injectable } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string){


    if(title != "" || title != undefined){

      this.title.setTitle(title);
    }else{
      this.title.setTitle('OptimizeTax');

    }
  }

  updateMetaTags(metaTags: MetaDefinition[]){

    
    if(metaTags.length != 0){

      metaTags.forEach(m=> this.meta.updateTag(m));
    }else{
      metaTags=[
        { name: 'description', content: 'One Stop Hub for your Bookkeeping and Tax Services.' },
        { property: 'og:title', content: 'OptimizeTax' },
        { name: 'keywords', content: 'online tax filing ,filing taxes online ,online tax services ,online tax preparation ,tax filing websites ,file my taxes online ,best online tax filing ,online tax return ,best place to file taxes ,file income tax ,income tax preparation ,tax filing near me ,cheapest tax service ,best free tax service ,filing state taxes ,tax professional near me ,Taxes by CPAs ,Expats Taxes ,Foreigned Owned LLC ,Proforma 1120 ,5472 Compliance' },
        { proprety: 'og:description', content: 'One Stop Hub for your Bookkeeping and Tax Services.' },
      ]

      metaTags.forEach(m=> this.meta.updateTag(m));
    }
  }
}