import { Component, OnInit } from '@angular/core';
import { WhyUniqallyService } from '../../_services/why-uniqally.service';

@Component({
  selector: 'app-why-uniqally',
  templateUrl: './why-uniqally.component.html',
  styleUrls: ['./why-uniqally.component.css']
})
export class WhyUniqallyComponent implements OnInit {

  contents : any = [];

  constructor(
    private whyUnqAllyService : WhyUniqallyService
  ) { }

  ngOnInit() {
    this.contents = this.whyUnqAllyService.getContents('PRICING');
  }

}
