import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../_helpers/http.service';

@Component({
  selector: 'app-platform-switcher',
  templateUrl: './platform-switcher.component.html',
  styleUrls: ['./platform-switcher.component.css']
})
export class PlatformSwitcherComponent implements OnInit {

  private uniqAllyBusinessURL;

  constructor(
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.uniqAllyBusinessURL = this.httpService.getBusinessUniqAllyURL();
  }

  // Redirect users to business.uniqally.com
  private goToUniqAllyBusiness() {
    window.open(this.uniqAllyBusinessURL);
  }

}
