import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-service-footer',
  templateUrl: './service-footer.component.html',
  styleUrls: ['./service-footer.component.css']
})
export class ServiceFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
