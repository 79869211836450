import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {HttpService} from '../_helpers/http.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private user;

  constructor(
    private http: HttpClient,
    private httpService: HttpService
  ) {
  }

  // Handle API errors with no response
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      return throwError(
        'Something bad happened; please try again later.');
    } else {
      return throwError(error.error);
    }
  }

  // Set user object
  setUser(user) {
    this.user = user;
  }

  // Get user object
  getUser() {
    return this.user;
  }

  // Social media authentication
  socialMediaAuthentication(params): Observable<any> {
    return this.http
      .post<any>(this.httpService.getApiURL() +  'customer/social-auth', params, this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Check Authentication status
  checkAuth(): Observable<any> {
    return this.http
      .post<any>(this.httpService.getApiURL() +  'customer/check-auth', [], this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }


  // Manual user registration
  registerManually(params): Observable<any> {
    return this.http
      .post<any>(this.httpService.getApiURL() +  'customer/register', params, this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Verify activation code
  verifyActivationCode(params): Observable<any> {
    return this.http
      .post<any>(this.httpService.getApiURL() +  'customer/verify-activation-code', params, this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Resend activation code
  resetActivationCode(): Observable<any> {
    return this.http
      .post<any>(this.httpService.getApiURL() +  'customer/resend-verification', [], this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Manual authentication
  authenticateManually(params): Observable<any> {
    return this.http
      .post<any>(this.httpService.getApiURL() +  'customer/authenticate', params, this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Verify authentication code
  verifyTwoFactorCode(params): Observable<any> {
    return this.http
      .post<any>(this.httpService.getApiURL() +  'customer/verify-two-factor', params, this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Resend authentication code
  resetTwoFactorCode(): Observable<any> {
    return this.http
      .post<any>(this.httpService.getApiURL() +  'customer/resend-two-factor', [], this.httpService.getHeaders())
      .pipe(
        catchError(this.handleError)
      );
  }

}
