import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLayoutComponent } from './_layouts/main-layout/main-layout.component';
import { PageNotFoundComponent } from './_components/page-not-found/page-not-found.component';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [{
      path: '',
      loadChildren: './_layouts/main-layout/main-layout.module#MainLayoutModule'
    }]
  },

  {
    path: '**', pathMatch: 'full',
    component: PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
