import { BrowserModule } from '@angular/platform-browser';


import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ɵs } from '@ng-select/ng-select';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MainLayoutComponent } from './_layouts/main-layout/main-layout.component';
import { ElementsModule } from './_elements/elements.module';
import { PageNotFoundComponent } from './_components/page-not-found/page-not-found.component';


import {  Title} from '@angular/platform-browser';  


let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("997681893277-pni1hbn85e52gb6quvbjnl7t2t9253d7.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("2755693241143588")
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,

    // layouts
    MainLayoutComponent,

    PageNotFoundComponent,




  ],

  imports: [
  
    BrowserModule,
    ElementsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    NgHttpLoaderModule.forRoot(),
    SocialLoginModule,
    BrowserAnimationsModule,
    FormsModule,
    NgSelectModule
  ],
  providers: [

    Title,
    StyleSheet,

    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
    NgSelectConfig,
    ɵs
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
