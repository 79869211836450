import { Component , ViewChild } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';

import { OnInit} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { MetaService } from "./_services/MetaService";

import { Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'uniqally';


  
  constructor(
    private router: Router,
    private spinner: SpinnerVisibilityService,
    private activatedRoute: ActivatedRoute,    private seoService: MetaService,
  ) {

    this.router.events.pipe(

      
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {


      
      let seoData = data['seo'];


      if(seoData != undefined ){

        this.seoService.updateTitle(seoData['title']);
        this.seoService.updateMetaTags(seoData['metaTags']);
      }else{


        let seoData =
         {
            title: 'OptimizeTax',
            metaTags: [
              { name: 'description', content: 'One Stop Hub for your Bookkeeping and Tax Services.' },
              { name: 'keywords', content: '5471 form , 5472 form,1120 taxes,1065 taxes , Proforma 1120  1120-f , Amazon fba,Florida Taxes , Wyoming taxes,1040NR , ITIN , CAA, Entity Taxes, 1065 partnership taxes, 1120 taxes for foreign owners, Single Member LLC Taxes,Disregarded entity taxes, how to get ITIN' },
              { property: 'og:title', content: 'One Stop Hub for your Bookkeeping and Tax Services.' },
              { proprety: 'og:description', content: 'One Stop Hub for your Bookkeeping and Tax Services.' },
            ]
          }
        
        this.seoService.updateTitle(seoData["title"]);
        this.seoService.updateMetaTags(seoData['metaTags']);

      }
    });

    

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.spinner.show();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          break;
        }
        default: {
          break;
        }
      }
    });
  }
}
