import { Component, OnInit } from '@angular/core';
import { AuthService, SocialUser } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { UserService } from '../../_services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-social-auth',
  templateUrl: './social-auth.component.html',
  styleUrls: ['./social-auth.component.css']
})
export class SocialAuthComponent implements OnInit {

  private user: SocialUser;
  private loggedIn: boolean;
  private showServerErrors = false;
  private serverErrMsg = '';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
      if(this.loggedIn){
        const params = {
          'provider' : this.user.provider.toLowerCase(),
          'token' : this.user.authToken
        };
        this.userService.socialMediaAuthentication(params).subscribe(response => {
          if (response.success === true) {
            this.userService.setUser(response.data.user);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('email_verified_at', response.data.user.email_verified_at);
            localStorage.setItem('is_social_auth', response.data.user.is_social_auth);
            localStorage.setItem('name', response.data.user.name);
            localStorage.setItem('is_two_factor', response.data.user.two_factor_verified);
            localStorage.setItem('email', response.data.user.email);
            if(response.data.user.email_verified_at === null){
              this.router.navigate(['/account-verification']);
            }else{
              this.router.navigate(['/two-factor-authentication']);
            }
          }
        }, error => {
          if(error.data.error.email){
            this.showServerErrors = true;
            this.serverErrMsg = error.data.error.email[0];
            setTimeout(() => {
              this.showServerErrors = false;
            }, 6000);
          }
        });
      }
    });
  }

  // Sign in with Google
  private signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  // Sign in with Facebook
  private signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

}
