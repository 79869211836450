import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HttpService {
  // API path
  // todo:get this value from environment variable
  // basePath = 'http://127.0.0.1:8000/api/';
  // basePath = 'https://api.vmanagetax.com/api/';
  basePath = 'https://apis.optimizetax.io/api/';
  userDashboardPath = 'http://localhost:4000/sign-in/';
  businessUniqAllyURL = 'http://business.uniqally.developers-in.com';

  // Headers
  headers =  new HttpHeaders({
    'Content-type' : 'application/json'
  });

  constructor(
    private http: HttpClient
  ) { }

  // Get headers with auth token if authenticated
  public getHeaders() {
    if (localStorage.getItem('token') !== null) {
      this.headers = this.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    }
    return {
      headers: this.headers
    };
  }

  // Return API base path
  public getApiURL() {
    return this.basePath;
  }

  // Return User Dashboard base path
  public getUserdashboardURL() {
    if (localStorage.getItem('token') !== null) {
      this.userDashboardPath = this.userDashboardPath + localStorage.getItem('success_code');
    }
    return this.userDashboardPath;
  }

  // Return business uniqally URL
  public getBusinessUniqAllyURL() {
    return this.businessUniqAllyURL;
  }

}
